.content {
  z-index: 90;

  width: 100vw;

  //display: inline-block;

  margin-top: 10vh;

  .page-wrapper {
    display: flex;

    flex-flow: row;

    .page-content {
      margin-top: 12.5%;
    }
  }
}
.not-found-wrapper {
  width: 100vw;
  height: 100vh;
  z-index: 101;
  position: fixed;

  top: 0;
  left: 0;

  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  text-align: center;

  .not-found-title {
    color: rgb(100, 70, 70);

    font-weight: bold;
    font-size: x-large;
  }

  .not-found-text {
    font-size: large;
  }

}
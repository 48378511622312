@font-face {
  font-family: main-font;
  src: url(D-DIN.ttf);
}


.container {
  overflow-x: hidden;
  overflow-y: visible;

  width: 100vw;
  height: 100vh;

  z-index: 80;

  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;

  font-family: Arial, serif; //main-font, serif, Arial;
}
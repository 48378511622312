.office-content {
  margin: 10%;

  .office-upper-title {
    letter-spacing: 1px;

    font-size: 2rem;

    margin-bottom: 5%;
  }

  .employee-entry {
    @media all and (max-width: 800px) { //mobile
      width: 45vw;
      height: 20vh;
    }

    @media all and (min-width: 800px) {
      width: 25vw;
      height: 30vh;
    }


    margin-bottom: 2vw;

    display: flex;
    flex-flow: row;

    background-color: #464646;

    border: 1vh solid #333333;

    .employee-image {
      height: 100%;
      max-width: 50%;

      float: left;
    }

    .employee-information {
      max-width: 50%;

      display: inline-block;
      overflow-wrap: break-word;
      word-wrap: break-word;

      margin: 5%;

      color: white;

      .employee-name {
        font-weight: bold;

        margin-bottom: 2%;

        font-size: 100%;
      }

      .employee-job {
        //font-weight: bold;
        font-size: 75%;
      }
    }
  }
}

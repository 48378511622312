.projects-container {
  justify-content: center; //horizontally
  align-items: center; //vertically

  top: 20vh;

  min-height: min-content;
  max-width: 40vw;

  margin-right: 2.5vw;
}

.project-container {
  //border-radius: 1vw;

  width: 25vh; //TODO THINK ABOUT ADDING ARROWS
  height: 25vh;
  margin: 5%;
  //flex-grow: 1;

  background-color: darkgray;

  border-color: rgba(100, 70, 70, 0.2);

  &:active {
    border-color: rgba(100, 70, 70, 1.0);
  }

  border-style: solid;

  box-shadow: 0.5vw 0.5vw 0.5vw #888888;

  justify-self: center;
  justify-content: center;

  position: relative;

  z-index: 85;

  overflow: hidden;
  display: inline-block;
  vertical-align: middle;

  .project-spoiler {
    width: 100%;
    height: 100%;

    margin: 0 auto;

    vertical-align: middle;
    justify-self: center;

    pointer-events: none;
  }
}

.project-preview {
  position: absolute;

  z-index: 150;

  min-width: 10vw;
  min-height: 3vh;

  pointer-events: none;

  border-color: #444444;//white;
  border-style: solid;
  border-width: 0.5vh;

  //border-radius: 1vh;
  backdrop-filter: blur(1vh);
  -webkit-backdrop-filter: blur(1vh);

  color: #444444;//white;
  //background-color: #443333;

  font-size: 2vh;
}

.focus-background {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  z-index: 105;
  position: fixed;

  backdrop-filter: blur(1.5vh);
}

.focus-wrapper {
  width: 100vw;
  height: 100vh;

  z-index: 105;
  position: fixed;

  top: 0;
  left: 0;

  justify-content: center; //horizontally
  align-items: center; //vertically
  display: flex;

  pointer-events: none;

  #focus {
    color: white;

    pointer-events: initial;

    vertical-align: middle;
    justify-self: center;

    background-color: #464646;

    //border-radius: 1vw;

    margin: 7.5vw;

    position: relative;
    z-index: 106;

    display: inline-block;
    //max-width: 50vw;
    //min-width: 20vw;
    //max-height: 95vh;
    //min-height: 30vh;
    padding: 0;

    overflow: hidden;

    box-shadow: 0 0 2vw gray;

    .focus-content {
      max-width: 50vw;
      max-height: 70vh;

      vertical-align: middle;
      //border-top-right-radius: 1vw;
      //border-top-left-radius: 1vw;

      z-index: 107;
    }

    .flex-container {
      display: flex;

      .focus-information {
        width: 0;
        flex-grow: 1;

        margin: 5%;
        overflow: visible;

        word-break: break-word;

        .focus-title {
          font-weight: bolder;

          font-size: 125%;

          margin-bottom: 2%;
        }

        .focus-data {
          font-size: 100%;

          .focus-type {
            font-weight: bold;
          }

          .focus-year {
            font-style: italic;
          }
        }
      }
    }
  }

  .focus-side {
    pointer-events: initial;

    width: 15vw;
    height: 15vh;

    position: relative;
    z-index: 102;

    //justify-self: left;
    overflow: hidden;
    //border-radius: 1vw;

    .focus-side-content {
      width: 100%;
      height: 100%;
    }
  }

  .focus-arrow-left {
    pointer-events: initial;

    display: inline-block;
    vertical-align: middle;
    justify-self: left;

    position: relative;
    z-index: 102;

    width: 10vw;
    height: 50vh;
  }

  .focus-arrow-right {
    pointer-events: initial;

    display: inline-block;
    vertical-align: middle;
    justify-self: right;

    position: relative;
    z-index: 102;

    width: 10vw;
    height: 50vh;
  }
}

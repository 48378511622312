:root {
  --scroll-bar-width: 0px;

  --primary-header-color: rgb(255, 255, 255);
}

%headerElement {
  position: inherit;
}

.header {
  position: fixed;
  width: 100vw;

  z-index: 100;

  pointer-events: none;

  margin {
    left: 0;
    top: 0;
  }

  div {
    pointer-events: initial;
  }

  #mainHeader {
    position: fixed;

    height: 10vh;
    width: inherit;

    z-index: 101;

    #mainHeaderBackground {
      width: inherit;
      height: inherit;
      position: inherit;

      background-color: rgb(70, 70, 70);
    }

    #mainHeaderContent {
      z-index: 103;

      width: inherit;
      height: inherit;
      position: inherit;

      color: var(--primary-header-color);

      display: flex;
      align-items: center;


      #mainHeaderContentSettings {
        height: inherit;

        position: inherit;
        z-index: 105;

        #mainHeaderContentSettingsWrapper {
          display: inline-block;
          overflow-y: hidden;

          top: 10%;
          left: 20%;
          height: 80%;

          border-radius: 20%;

          position: absolute;
          z-index: 105;

          #mainHeaderContentSettingsBackground {
            height: 100%;
            width: 100%;

            &:hover {
              background-color: rgb(100, 70, 70);
              opacity: 0.2;
            }

            &:active {
              background-color: rgb(100, 70, 70);
              opacity: 1;
            }

            z-index: 102;

            position: absolute;
          }

          #mainHeaderContentSettingsContent {
            height: 100%;
            z-index: 103;

            pointer-events: none; // enables hover in headerContentSettingsBackground

            position: relative;
          }
        }
      }

      #mainHeaderContentText {
        justify-content: center; //horizontally
        align-items: center; //vertically
        display: flex;

        justify-self: center;

        top: 25%;
        height: 50%;

        //left: 25%;
        width: 100%;

        position: absolute;
        z-index: 104;

        font-size: 2vh;

        .mainHeaderContentTextPart {
          margin: 0 4.5vw;

          text-align: center;

          display: inline-block;
          vertical-align: middle;
          justify-self: left;

          width: 15vw;
        }
      }
    }
  }

  #optionHeader {
    margin-top: 10vh;

    height: 7vh;
    width: 100vw;

    position: fixed;

    #optionHeaderBackground {
      width: 100vw;
      height: inherit;
      position: inherit;

      background-color: rgb(90, 90, 90);

      z-index: 102;
    }

    #optionHeaderContent {
      justify-content: center; //horizontally
      align-items: center; //vertically
      display: flex;

      top: 25%;
      height: 50%;

      left: 10%;
      width: 80%;

      position: absolute;

      font-size: 2vh;

      z-index: 104;

      justify-self: center;

      .optionHeaderContentText {
        text-align: center;
        text-decoration: unset;

        display: inline-block;
        vertical-align: middle;
        justify-self: left;

        max-width: 15vw;

        //width: 10%;
        //height: 50%;

        //padding: 1%;
        padding: 0.5% 4%;

        //background-color: white;

        border-width: 0.5vh;
        border-color: var(--primary-header-color);
        border-style: solid;

        color: var(--primary-header-color);

        margin: 0 3vw;

        &:hover {
          border-color: #443333;
          color: #443333;
        }

        &:active {
          border-color: var(--primary-header-color);
          color: var(--primary-header-color);

          background-color: #443333;
        }
      }
    }
  }
}

#headerImage {
  float: right;
  background-color: #282c34;
  max-height: calc(10vh * 1.7);
  max-width: calc(7.2vh * 1.7);
  z-index: 105;

  position: relative;

  #headerImageContent {
    max-width: inherit;
    max-height: inherit;
  }

}

.stretching {
  width: 100%;
  height: 0;
  display: inline-block;
}



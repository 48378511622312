.footer {
  width: 100vw;

  background-color: #464646;

  bottom: 0;


  .main-footer {
    height: 20vh;

    color: white;

    position: relative;
    z-index: 80;

    display: flex;
    flex-flow: column;

    justify-content: center; //horizontally
    align-items: center; //vertically

    font-size: 2vh;

    .footer-image-container {
      width: 80%;
      height: 50%;

      display: flex;
      flex-flow: row;

      justify-self: center;

      justify-content: center; //horizontally
      align-items: center; //vertically

      margin-bottom: 1%;

      .footer-image {
        height: 100%;

        //float: left;

        object-fit: cover;

        margin-right: 1%;
      }

      .footer-image-description {

      }
    }

    .footer-text {
      text-align: center;

      a {
        color: rgb(180, 95, 95);

        text-decoration: unset;

        &:hover {
          color: rgb(210, 105, 105);
        }

        &:active {
          color: rgb(120, 50, 30);
        }
      }
    }
  }
  .safari-addition {
    width: 100vw;
    height: 0;

    @supports (-webkit-touch-callout: none) {
      // CSS specific to iOS devices
      height: 10vh;
    }
  }
}

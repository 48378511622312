.contact-content {
  margin-left: 10%;

  .contact-upper-title {
    letter-spacing: 1px;

    font-size: 2rem;

    margin-bottom: 5%;

  }

  #map {
    height: 30vh;
    width: 30vh;

    margin-bottom: 5%;

    #map-content {
      width: 100%;
      height: 100%
    }
  }


  #qr {
    margin-top: 5%;

    width: 10vh;
    height: 10vh;

    #qr-content {
      width: 100%;
      height: 100%;
    }
  }

  .contact-segment {
    margin-bottom: 10%;
  }

  .contact-field {
    margin-bottom: 1%;

    .contact-field-name {
      font-weight: bold;
    }

    .contact-field-value {

    }
  }

  .address-field {

  }
}
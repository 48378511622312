.home-content {

  max-width: 60%;

  margin: 5%;

  overflow-y: visible;

  .home-upper-title {
    letter-spacing: 1px;

    font-size: 2rem;

    margin-bottom: 5%;
    word-wrap: break-word;
  }

  .home-information {
    text-align: justify;

    .field {

      margin-bottom: 2%;

      .field-title {
        font-weight: bold;

        &:hover {
          color: #443333;
        }
      }

      .field-value {
      }
    }
  }
}